import React, { useRef, useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import { DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import Streetview from 'react-google-streetview';
import deleteIcon from './assets/remove.png';
import appStore from "./appStore";

const googleMapsApiKey = 'AIzaSyBoo7Cyn3r3c66hDD29orA-KzZZ9QtGO3g';

function hexToRgbA(hex, opacity){
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if(c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
    }
}

const ColorReference = (props) => {
    const { colors } = props;
    return (
        <div className={'props-window colorRef'}>
            {colors.map(c =>
                <div>
                    <span style={{
                        backgroundColor: c.onlyBorder ? '#ffffff' : hexToRgbA(c.color, '0.3'),
                        borderColor: c.color
                    }} />
                    <label>{c.name}</label>
                </div>
            )}
        </div>
    );
}

const PolygonProps = (props) => {
    if (!props.polygon)
        return;
    const type = props.polygon.type === undefined ? '' : props.polygon.type;
    return (
        <div className={'props-window'}>
            <label>Tipo</label>
            <select onChange={(e) => props.onChangeType(e.target.value)}>
                { ['', 'hogar', 'comercio', 'mixto'].map(x => {
                    if (type === x)
                        return <option key={'type'+x} value={x} selected>{x === '' ? 'no definido' : x}</option>
                    return <option key={'type'+x} value={x}>{x === '' ? 'no definido' : x}</option>
                })}
            </select>
            <label>Pisos</label>
            <select onChange={(e) => props.onChangeStories(e.target.value)}>
                { props.polygon.stories === undefined &&
                    <option key={'storiesundef'} value={undefined} selected>no definido</option>
                }
                { Array.from({length: 50}, (x, i) => i).map(x => {
                    if (props.polygon.stories === parseInt(x))
                        return <option key={'stories'+x} value={x} selected>{x === 0 ? 'solo planta baja' : x}</option>
                    return <option key={'stories'+x} value={x}>{x === 0 ? 'solo planta baja' : x}</option>
                })}
            </select>
        </div>
    );
}

const MapComponent = () => {
    const mapRef = useRef();
    const drawingManagerRef = useRef();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey,
        libraries: ['places', 'drawing']
    });
    const defaultCenter = { lat: -22.75609, lng: -43.44916 };
    const [polygons, _setPolygons] = useState({});
    const [clickPosition, setClickPosition] = useState(defaultCenter);
    const [editingPolygonUUID, setEditingPolygonUUID] = useState();
    const [center, setCenter] = useState(defaultCenter);
    const [init, setInit] = useState(false);

    if (!init) {
        appStore.getPolygons((polygons) => {
            setInit(true);
            _setPolygons(polygons);
        });
    }

    const deleteIconStyle = {
        cursor: 'pointer',
        backgroundImage: `url(${deleteIcon})`,
        height: '24px',
        width: '24px',
        marginTop: '5px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: "0px",
        left: "calc(25vw + 50px)",
        border: "solid 1px #ccc",
        zIndex: 99999
    };

    const colorCompleto = '#0B670B';
    const colorIncompleto = '#AD3A06';
    const colorSeleccionado = '#0000FF';
    const colors = [
        { color: colorCompleto, name: 'Datos Completos' },
        { color: colorIncompleto, name: 'Datos Incompletos' },
        { color: colorSeleccionado, name: 'Seleccionado', onlyBorder: true }
    ];

    const polygonOptions = {
        fillOpacity: 0.3,
        strokeWeight: 2,
        draggable: true,
        editable: true,
        fillColor: colorCompleto,
        strokeColor: colorCompleto
    };

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const setPolygons = (p, editedUUID) => {
        if (p[editedUUID])
            appStore.savePolygon(p[editedUUID]);
        else
            appStore.deletePolygon(editedUUID);
        _setPolygons(p);
    };

    const onLoadMap = (map) => { mapRef.current = map; };
    const onLoadDrawingManager = drawingManager => { drawingManagerRef.current = drawingManager; };
    const onLoadPolygon = (polygon, uuid) => {
        console.log('onLoadPolygon');
        let _polygons = { ...polygons };
        if (!_polygons[uuid]) _polygons[uuid] = {};
        _polygons[uuid].ref = polygon;
        if (Object.keys(_polygons).length === Object.values(_polygons).filter(p => p.ref).length) {
            _setPolygons(_polygons);
        }
    };
    const onClickPolygon = (e, uuid) => {
        setClickPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        setEditingPolygonUUID(uuid);
    };
    const onClickMap = (e) => {
        const point = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setClickPosition(point);
        // Create a polygon to store data
        const sideLength = 0.00003;
        const lat = point.lat - sideLength / 2;
        const lng = point.lng - sideLength / 2;
        const newPolygon = [
            { lat, lng },
            { lat: lat + sideLength, lng: lng },
            { lat: lat + sideLength, lng: lng + sideLength },
            { lat: lat, lng: lng + sideLength },
            { lat, lng },
        ];
        const uuid = uuidv4();
        const newPolygons = { ...polygons };
        newPolygons[uuid] = { uuid, polygon: newPolygon, type: undefined, stories: undefined };
        setPolygons(newPolygons, uuid);
        setEditingPolygonUUID(uuid);
    };

    const onOverlayComplete = (overlayEvent) => {
        drawingManagerRef.current.setDrawingMode(null);
        if (overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            overlayEvent.overlay?.setMap(null);

            const uuid = uuidv4();
            const newPolygons = { ...polygons };
            newPolygons[uuid] = { uuid, polygon: newPolygon, type: undefined, stories: undefined };
            setPolygons(newPolygons, uuid);
        }
    }

    const onDeleteDrawing = () => {
        const newPolygons = { ...polygons };
        delete newPolygons[editingPolygonUUID];
        setPolygons(newPolygons, editingPolygonUUID);
    }

    const onEditPolygon = (e, uuid) => {
        const polygonRef = polygons[uuid].ref;
        if (polygonRef) {
            const coordinates = polygonRef.getPath().getArray().map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
            const newPolygons = { ...polygons };
            newPolygons[uuid].polygon = coordinates;
            setPolygons(newPolygons, uuid);
        }
    }

    return (
        init && isLoaded
            ?
            <div className='main-map-container'>
                { drawingManagerRef.current &&
                    <div
                        onClick={onDeleteDrawing}
                        title='Delete shape'
                        style={deleteIconStyle}>
                    </div>
                }
                <GoogleMap
                    zoom={20}
                    center={center}
                    onLoad={onLoadMap}
                    mapContainerClassName="map-container"
                    onTilesLoaded={() => setCenter(null)}
                    onClick={(e) => onClickMap(e)}
                >
                    <DrawingManager
                        onLoad={onLoadDrawingManager}
                        onOverlayComplete={onOverlayComplete}
                        options={drawingManagerOptions}
                    />
                    {
                        Object.values(polygons).map((obj, index) => {
                            const options = { ...polygonOptions };
                            if (!obj.type || obj.stories === undefined) {
                                options.fillColor = colorIncompleto;
                                options.strokeColor = colorIncompleto;
                            }
                            if (obj.uuid === editingPolygonUUID) {
                                options.strokeColor = colorSeleccionado;
                            }
                            return (
                            <Polygon
                                key={index}
                                onLoad={(polygon) => onLoadPolygon(polygon, obj.uuid)}
                                onMouseDown={(e) => onClickPolygon(e, obj.uuid)}
                                onMouseUp={(e) => onEditPolygon(e, obj.uuid)}
                                onDragEnd={(e) => onEditPolygon(e, obj.uuid)}
                                options={options}
                                paths={obj.polygon}
                                draggable
                                editable
                            />
                            )
                        })
                    }
                    <ColorReference colors={colors} />
                </GoogleMap>
                <div className={'map-container'}>
                    <Streetview
                        apiKey={googleMapsApiKey}
                        streetViewPanoramaOptions={{
                            position: clickPosition,
                            pov: { heading: 0, pitch: 0 },
                            zoom: 1,
                        }}
                    />
                </div>
                {editingPolygonUUID &&
                    <PolygonProps
                        key={editingPolygonUUID}
                        polygon={polygons[editingPolygonUUID]}
                        onChangeStories={(val) => {
                            const newPolygons = { ...polygons };
                            newPolygons[editingPolygonUUID].stories = val;
                            setPolygons(newPolygons, editingPolygonUUID);
                        }}
                        onChangeType={(val) => {
                            const newPolygons = { ...polygons };
                            newPolygons[editingPolygonUUID].type = val;
                            setPolygons(newPolygons, editingPolygonUUID);
                        }}
                    />
                }
            </div>
            :
            <div>
                { loadError }
            </div>
    );
}

export default MapComponent;