
class AppStore {
    gettingPolygons = false;
    URL = '/api/polygons';

    constructor() {
        const hostname = window.location.hostname.toLowerCase().split('.');
        if (hostname[0] === 'localhost')
            this.URL = 'http://localhost:6001/polygons';
    }

    coordsToWKT(coords) {
        let wktPolygon = 'POLYGON((';
        coords.forEach(p => {
            wktPolygon += p.lng + ' ' + p.lat + ', ';
        });
        //wktPolygon = wktPolygon.slice(0, -2);
        wktPolygon += coords[0].lng + ' ' + coords[0].lat;
        wktPolygon += '))';
        return wktPolygon;
    }

    WKTToCoords(wkt) {
        if (wkt.toLowerCase().slice(0, 9) !== "polygon((")
            return wkt;
        const coords = [];
        wkt.slice(9).slice(0, -2).split(',').forEach(lngLatStr => {
            const lngLat = lngLatStr.split(' ');
            if (lngLat.length === 2) {
                coords.push({
                    lng: parseFloat(lngLat[0]),
                    lat: parseFloat(lngLat[1])
                });
            }
        });
        return coords;
    }

    getPolygons(cb) {
        if (this.gettingPolygons) return;
        this.gettingPolygons = true;
        fetch(this.URL, {
            method: 'GET'
        }).then((response) => {
            if (!response.ok) {
                cb();
                return;
            }
            response.text().then(text => {
                const data = JSON.parse(text);
                const polygons = {};
                data.forEach(p => {
                    polygons[p.uuid] = {
                        ...p,
                        polygon: this.WKTToCoords(p.polygon)
                    }
                });
                cb(polygons);
                this.gettingPolygons = false;
            });
        }).catch((error) => {
            console.log('get catch', error);
        });
    }

    savePolygon(polygon) {
        if (polygon.polygon.length === 0)
            return;
        const wktPolygon = this.coordsToWKT(polygon.polygon);
        fetch(this.URL, {
            method: 'POST',
            body: JSON.stringify({
                uuid: polygon.uuid,
                polygon: wktPolygon,
                type: polygon.type,
                stories: polygon.stories
            }),
            headers: {}
        }).catch((error) => {
            console.log('save catch', error);
        })
    }

    deletePolygon(uuid) {
        fetch(this.URL, {
            method: 'DELETE',
            body: JSON.stringify({ uuid }),
            headers: {}
        }).catch((error) => {
            console.log('delete catch', error);
        })
    }
}

const appStore = new AppStore();
export default appStore;